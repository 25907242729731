// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  //LOCALHOST

  // appurl: 'http://localhost:4200',
  // apiUrl: 'http://localhost:50279/',
  // addclickurl: 'http://localhost:50279/Report/UserDetails/',
  // departmenturl: 'http://localhost:50279/Report/Department/',
  // departmentreporturl: 'http://localhost:50279/Report/DepartmentSummary/Department/',
  // titleurl: 'http://localhost:50279/Report/Title/',
  // titlereporturl: 'http://localhost:50279/Report/TitleSummary/Title/',
  // preparereporturl: 'http://localhost:50279/Report/Flash/Title/',

  //DEV Environment

  //hostName: 'co1ni0sk3c.execute-api.us-west-2.amazonaws.com',
  //appurl: 'https://dev.mfr.nbcuni.com/',  
  //apiUrl:"https://co1ni0sk3c.execute-api.us-west-2.amazonaws.com/Prod/",
  //addclickurl: 'https://co1ni0sk3c.execute-api.us-west-2.amazonaws.com/Prod/UserDetails/',
  //departmenturl: 'https://co1ni0sk3c.execute-api.us-west-2.amazonaws.com/Prod/Report/Department/',
  //departmentreporturl: 'https://co1ni0sk3c.execute-api.us-west-2.amazonaws.com/Prod/Report/DepartmentSummary/Department/',
  //titleurl: 'https://co1ni0sk3c.execute-api.us-west-2.amazonaws.com/Prod/Report/Title/',
  //titlereporturl: 'https://co1ni0sk3c.execute-api.us-west-2.amazonaws.com/Prod/Report/TitleSummary/Title/',
  //preparereporturl: 'https://co1ni0sk3c.execute-api.us-west-2.amazonaws.com/Prod/Report/Flash/Title/',


  //QA Environment

  //appurl: 'https://qa.mfr.nbcuni.com/',
  //hostName: 'rf2o9lusb1.execute-api.us-west-2.amazonaws.com',
  // apiUrl: "https://rf2o9lusb1.execute-api.us-west-2.amazonaws.com/Prod/",
  // addclickurl: 'https://rf2o9lusb1.execute-api.us-west-2.amazonaws.com/Prod/UserDetails/',
  // departmenturl: 'https://rf2o9lusb1.execute-api.us-west-2.amazonaws.com/Prod/Report/Department/',
  // departmentreporturl: 'https://rf2o9lusb1.execute-api.us-west-2.amazonaws.com/Prod/Report/DepartmentSummary/Department/',
  // titleurl: 'https://rf2o9lusb1.execute-api.us-west-2.amazonaws.com/Prod/Report/Title/',
  // titlereporturl: 'https://rf2o9lusb1.execute-api.us-west-2.amazonaws.com/Prod/Report/TitleSummary/Title/',
  // preparereporturl: 'https://rf2o9lusb1.execute-api.us-west-2.amazonaws.com/Prod/Report/Flash/Title/',

  //STAGE Environment
 
  appurl: 'https://stage.mfr.nbcuni.com/',
  hostName: 'xvsibdwox1.execute-api.us-west-2.amazonaws.com',
  apiUrl: "https://api.stage.mfr.nbcuni.com/",
  addclickurl: 'https://api.stage.mfr.nbcuni.com/UserDetails/',
  departmenturl: 'https://api.stage.mfr.nbcuni.com/Report/Department/',
  departmentreporturl: 'https://api.stage.mfr.nbcuni.com/Report/DepartmentSummary/Department/',
  titleurl: 'https://api.stage.mfr.nbcuni.com/Report/Title/',
  titlereporturl: 'https://api.stage.mfr.nbcuni.com/Report/TitleSummary/Title/',
  preparereporturl: 'https://api.stage.mfr.nbcuni.com/Report/Flash/Title/',
  authURL: 'fss.stg.inbcu.com/fss',
  tokenurl: 'https://fss.stg.inbcu.com/fss/as/token.oauth2',
  scope: 'AlwaysMFAEnabled openid',
  clientId: 'MktFlashReport',
  pfidpadapterId: 'iNBCUUATGrpSMPAdp',
  logoutUrl: 'https://login.stg.inbcu.com/ssologin/logoff.jsp',
  service: 'execute-api',
  region: 'us-west-2',
  idleTime : 240,
  identitypool : 'us-west-2:c610c9b9-ebf5-4dc7-96a5-b619b216c9bb', 
  slatebucket: 'nbcu-mfr-stage-custom-slatesummary',
  mediabucket: 'nbcu-mfr-stage-custom-mediasummary',  
  CognitoRoleARN: 'arn:aws:iam::887964033604:role/service-role/custrole-Cognito_MFR_Identity_PoolAuth_Role',
  Secret: 'mfr/secret/app'
};
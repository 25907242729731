import { Component, OnInit, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../../service/apiCall.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Http } from '@angular/http';
import { ExportService } from '../../service/export.service';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { bool } from 'aws-sdk/clients/signer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AwsCommonService } from '../../service/aws-common.service';
import { CookieService } from 'ngx-cookie-service';
import { delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs/observable/interval';

@Component({
  selector: 'app-viewallreport',
  templateUrl: './viewallreport.component.html',
  styleUrls: ['./viewallreport.component.scss']
})

export class ViewAllReportComponent implements OnInit {
  isDownloadDisable:boolean=false;
  showErrorDialogPopup: boolean = false;
  selectedVal: any;
  reportValues: any;
  selectedAll: any;
  validations: any = [];
  names: any[];
  insertedDate: any[];
  titles: any = [];
  flashReport: any = [];
  flashReportAll: any = [];
  filterExcelName: any = [];
  isShow = false;
  excelNames: any[];
  selectedyear: any;
  selectedmonth: any;
  selecteddate: any;
  message: any;
  countCheck: Subscription;
  titlePermission: any;
  userDetails: any;
  showAlertDialogPopup : boolean = false;
  userHasTitle : any = [];
  titleList : any;
  constructor(private _http: Http, private router: Router, private modalService: NgbModal,
    private apiService: ApiService, private exportService: ExportService, private toastr: ToastrService,
    private awsCommonService: AwsCommonService, private cookieService: CookieService) {

  }

  dateValues: any;
  yearValues: any;
  monthValues: any;
  toastrObject:ActiveToast<any>;
  async ngOnInit() {
    

    if (this.cookieService.get('emailURL') == environment.appurl + "viewreports?from=email") {
      this.isDownloadDisable = true;
      
    };
    await this.getTitles();
    this.countCheck = interval(1000).subscribe((val) => { this.apiService.publishvaluecheck().subscribe(count => {
      let result = count;
      console.log(count);
      if(parseInt(count) > 0)
      {
        this.isShow = true;
        this.toastr.toastrConfig.preventDuplicates=true;
        this.toastrObject=this.toastr.info('Currently report is being published, please wait until you receive an email notification', 'Alert', { timeOut: 900000, extendedTimeOut: 0, tapToDismiss: false });
      }
      else
      {
        this.isShow = false;
        this.countCheck.unsubscribe();
        if(this.toastrObject) {
          this.toastr.clear(this.toastrObject.toastId);
        }
      }
    });      
    });
    this.apiService.getNonAdminInsertedDateById().subscribe(result => {
      this.yearValues = result;
	  if(this.yearValues.length > 0)
      {
      this.selectedyear = this.yearValues[0].year;
      this.getMonth();
	  }
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  exportpdfClick() {

    this.awsCommonService.getApiRequest(environment.apiUrl + this.selectedVal, 'GET')
      .subscribe(result => {

        this.reportValues = result;
      },
      err => {
        this.isShow = false;
        this.showErrorDialogPopup = true;          
      },
      () => {
      }
    );
  }

  selectAll(event: any) {

    for (var i = 0; i < this.names.length; i++) {
      this.names[i].selected = this.selectedAll;

    }

    for (var i = 0; i < this.names.length; i++) {
      this.titles.push({ titleId: this.names[i].titleId, titleName: this.names[i].titlename });
    }


  }

  checkIfAllSelected(event: any) {

    if (event.selected == true) {
      let titleId = event.titleId;
      this.titles.push({ titleId: event.titleId, titleName: event.titlename });

    }
    else {
      let titleId = event.titleId;
      this.titles = this.titles.filter(obj => obj.titleId !== titleId);

    }
  }

  open() {
    this.validations = [];
    const dd = this.names.find(x => x.selected === true);
    if (dd === undefined) {
      this.validations.push('Please Select Title');
    }
  }

  openDialog() {

    this.validations = [];
    const dd = this.names.find(x => x.selected === true);
    if (dd === undefined) {
      this.validations.push('Please Select Title');
    }
    if (this.validations.length > 0) {
      return;
    }
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  getMonth() {
    this.apiService.getNonAdminInsertedMonthById(this.selectedyear).subscribe(result => {
      this.monthValues = result;
      this.selectedmonth = this.monthValues[0].month;
      this.getDay();
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  getDay() {
    this.apiService.getNonAdminInsertedDayById(this.selectedmonth, this.selectedyear).subscribe(result => {
      this.dateValues = result;
      this.selecteddate = this.dateValues[0].date;
      
      if(this.cookieService.get('emailURL') == environment.appurl + "viewreports?from=email"){
        this.isDownloadDisable=true;
        this.exportPDFFromMail('Pdf');
      }   
      else
        this.isDownloadDisable=false;

      this.countCheck = interval(1000).subscribe((val) => { this.apiService.publishvaluecheck().subscribe(count => {
        let result = count;
        console.log(count);
        if(parseInt(count) > 0)
        {
          this.isShow = true;
          this.toastr.toastrConfig.preventDuplicates=true;
          this.toastrObject= this.toastr.info('Currently report is being published, please wait until you receive an email notification', 'Alert', { timeOut: 900000, extendedTimeOut: 0, tapToDismiss: false });
        }
        else
        {
          this.isShow = false;
          this.countCheck.unsubscribe();
          if(this.toastrObject) {
            this.toastr.clear(this.toastrObject.toastId);
          }
        }
      });      
      });
      
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  exportPDFFromMail(fileFormat){
    var DateObj = new Date();

    this.isShow = true;
    this.validations = [];
    this.message = undefined;
    this.selectedVal = this.selectedyear + "-" + this.selectedmonth + "-" + this.selecteddate;
    this.toastr.toastrConfig.preventDuplicates=true;
        this.toastr.warning('Preparing the report for download. May take a few minutes to complete. Please stand by.', 
      'Alert', 
      { timeOut: 900000, extendedTimeOut: 0, tapToDismiss: false });
    this.apiService.getUsersById(sessionStorage.getItem('SSO')).subscribe(result => {
      this.userDetails = result;
      this.apiService.getTitlesPermission().subscribe(res => {
        this.titlePermission = res;
        this.titleList.forEach(element => {
          let dd = this.titlePermission.filter(x => x.titleId === element.titleId && x.userId === this.userDetails.userId && x.activeFlag === 1);
          if(dd.length>0)
          {
          this.userHasTitle = dd;
            return;
          }
        });
          if(this.userHasTitle.length<1)
          {
            this.isShow = false;
            this.showAlertDialogPopup = true;
            this.toastr.clear();
            return;
          }
          else
          {
            
            this.isShow = true;
            this.apiService.getNonAdminReportById(fileFormat, this.selectedVal)
            .subscribe(response => {
              this.apiService.getGeneratedPDFMail(response.res).subscribe(res => {
                this.message = undefined;
                const a = document.createElement('a');
                a.href = res.res;
                a.target ="_blank";
                a.download = 'ConsolidatedReport_' + Date.now + '.pdf';
                a.click();
                this.isShow = false;
                this.isDownloadDisable=false;
                this.toastr.clear();
              }),
                err => {
                  this.isShow = false;
                  this.message = "No Report Available";
                  this.isDownloadDisable=false;
                this.toastr.clear();

                },
                () => {
                  this.isDownloadDisable=false;
                }
            },
              err => {
                this.isShow = false;
                this.showErrorDialogPopup = true;
              },
              () => {
              }
            );
            this.removeItem('emailURL');
          }
      },
      err => {      
        this.showErrorDialogPopup = true;          
      },
      () => {
      }
    );
    });

  }

  removeItem(name) {
    document.cookie = name+'=; Max-Age=-99999999;';  
}

  exportexcelClick(fileFormat) {	
    let url: any;
    this.isShow = true;
    this.validations = [];
    this.message = undefined;
    this.selectedVal = this.selectedyear + "-" + this.selectedmonth + "-" + this.selecteddate
    if (this.selectedyear == undefined || this.selectedmonth == undefined || this.selecteddate == undefined) {
      this.message = undefined;
      this.isShow = false;
      this.validations.push('Choose Year, Month & Date to download the report');
    }
    else {
        this.isShow = true;
        this.getUserById(fileFormat);
    }
  }

  getUserById(fileFormat)
  {
    this.apiService.getUsersById(sessionStorage.getItem('SSO')).subscribe(result => {
      this.userDetails = result;
      this.getTitlePermission(this.userDetails,fileFormat);
    });
  }

  getTitlePermission(userDetails, fileFormat) {
    this.apiService.getTitlesPermission().subscribe(res => {
      this.titlePermission = res;
      this.titleList.forEach(element => {
        let dd = this.titlePermission.filter(x => x.titleId === element.titleId && x.userId === this.userDetails.userId && x.activeFlag === 1);
        if(dd.length>0)
        {
        this.userHasTitle = dd;
          return;
        }
      });
        if(this.userHasTitle.length<1)
        {
          this.isShow = false;
          this.showAlertDialogPopup = true;
          return;
        }
        else
        {
          this.isShow = true;
          this.isDownloadDisable=true;
          this.toastr.toastrConfig.preventDuplicates=true;
          
      this.toastr.warning('Preparing the report for download. May take a few minutes to complete. Please stand by.', 
      'Alert', 
      {closeButton:false, disableTimeOut:true, tapToDismiss: false });
          this.apiService.getNonAdminReportById(fileFormat, this.selectedVal)
          .subscribe(response => {
            this.apiService.getGeneratedPDFMail(response.res).subscribe(res => {
              this.message = undefined;
              const a = document.createElement('a');
              a.href = res.res;
              a.target ="_blank";
              a.download = 'ConsolidatedReport_' + Date.now + '.pdf';
              a.click();
              this.isShow = false;
              this.isDownloadDisable=false;
              this.toastr.clear();
            }),
              err => {
                this.isShow = false;
                this.isDownloadDisable=false;
                this.toastr.clear();
                this.message = "No Report Available";
              },
              () => {
              }
          },
            err => {
              this.isShow = false; 
              this.isDownloadDisable=false;
              this.showErrorDialogPopup = true;
            },
            () => {
            }
          );
        }
    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
  async getTitles() {
   await this.apiService.getTitles().toPromise().then(res=>{this.titleList = res;}).catch( err => {      
      this.showErrorDialogPopup = true;          
    });
  } 
}